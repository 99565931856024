import React from 'react';
import mockup from './mockup.png';
import './Home.css';
import apple from './apple.png';

function Home() {
    return (
      <div>
        <div className="main-container fade-in">
          
          <div className="text-container">
            <div className="header-text delay800">Shop your screenshots</div>
            <div className="supporting-text">A personalized shopping app powered by your camera roll. Easily shop your screenshots from Instagram, TikTok, Pinterest and e-commerce sites.</div> 
            <div className="app-link">
              <a href="https://apps.apple.com/us/app/capsule-shop-your-screenshots/id1642947474"  target="_blank" rel="noopener noreferrer" className="download-button">
                  <img src={apple} alt="Apple Icon" className="apple" />
                  Download App</a>
            </div>
          </div>

          <div className="mockup-container">
            <img src={mockup} alt="Mockup" className="responsive-mockup" />          
          </div>
        </div>
      </div>
    );
  }
  

export default Home;