import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import Privacy from './Privacy';
import './App.css';
import logo from './logo.png';
import tiktok from './tiktok.png';
import instagram from './instagram.png';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="navigation-container">
          <div className="logo-container" >
            <Link to="/">
              <img className="logo" src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="links-container">
            <div className="social-media-link">
              <a href="https://www.instagram.com/shopcapsule.ai"  target="_blank" rel="noopener noreferrer" className="social-media-button">
                <img className="instagram" src={instagram} alt="Logo" />
              </a>
            </div>
            <div className="social-media-link">
              <a href="https://www.tiktok.com/@shopcapsule.ai"  target="_blank" rel="noopener noreferrer" className="social-media-button">
                <img className="tiktok" src={tiktok} alt="Logo" />
              </a>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <footer className="sticky-footer">
          <div className="footer-container">
            <div className="rights-reserved-container">
              © 2024 CAPSULE. All rights reserved.
            </div>
            <div className="footer-links-container">
              <Link to="/privacy" className="privacy-link">Privacy</Link> 
              <Link to="/contact" className="contact-link">Contact</Link>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
